import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/SEO"
import BlogItem from "../components/BlogItem"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { HeaderContainer } from "../components/HeaderContainer"
import { TwoRowContainer } from "../components/TwoRowContainer"

const HighlightedText = styled.span`
  color: var(--secondaryColor);
  font-weight: var(--boldWeight);
`

const BigLogoContainer = styled.div`
  margin-left: 2rem;
  min-width: 30%;
`

const HomePage = ({ data }) => {
  const {
    mainMenu,
    logo,
    translations
  } = data
  const image = getImage(logo)
  
  return (
    <>
      <Seo />
      <HeaderContainer>
        <h1>
          {translations.frontmatter.preheader}
          <br />
          <i>
            {translations.frontmatter.headline} <HighlightedText>{translations.frontmatter.highlight}</HighlightedText>
          </i>
        </h1>
        <BigLogoContainer>
          <GatsbyImage image={image} alt={"Wina Węgierskie Logo"}/>
        </BigLogoContainer>
      </HeaderContainer>

      {mainMenu.edges.slice(0, 1).map(({ node }, index) => (
        <BlogItem nodeObj={node} index={index} />
      ))}
      
      <TwoRowContainer>
        {mainMenu.edges.slice(1, 3).map(({ node }, index) => (
          <BlogItem nodeObj={node} index={index} />
        ))}
      </TwoRowContainer>

      {mainMenu.edges.slice(3).map(({ node }, index) => (
          <BlogItem nodeObj={node} index={index} />
        ))}
    </>
  )
}

export default HomePage

export const indexQuery = graphql`
  query indexQuery($language: String!, $path: String!) {
    logo: file(relativePath: { eq: "images/big_logo.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    translations: markdownRemark(
      fields: {
        language: { eq: $language }
        path: { eq: $path }
      }
    ) {
      frontmatter {
        headline
        preheader
        highlight
      }
    }
    mainMenu: allMarkdownRemark(
      sort: { fields: [frontmatter___order], order: ASC }
      limit: 6
      filter: {
        fileAbsolutePath: { regex: "/mainMenu/" }
        fields: { language: { eq: $language } }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            path
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP]
                )
              }
            }
            featuredImageAlt
          }
          excerpt
        }
      }
    }
  }
`
